import React from "react";
import Lottie from "lottie-react";
import FlymyaLoading from "../lotties/loading_animation.json";

const FlymyaIconLoading = (props) => (
  <>
    <div className="loadingGifDiv">
      <Lottie animationData={FlymyaLoading} loop={true} />
      {props?.loadingMessage && (
        <p className="loadingMessage">{props?.loadingMessage}</p>
      )}
    </div>
  </>
);

export default FlymyaIconLoading;
