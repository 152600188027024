import React, { createContext, useState } from "react";

// Create a context
export const GorgiasContext = createContext();

// Create a provider component
export const GorgiasProvider = ({ children }) => {
  const [gorgiasActive, setGorgiasActive] = useState(false);

  const updateGorgiasState = (newState) => {
    setGorgiasActive(newState);
  };

  return (
    <GorgiasContext.Provider value={{ gorgiasActive, updateGorgiasState }}>
      {children}
    </GorgiasContext.Provider>
  );
};
