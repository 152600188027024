import {
  B2B_ClASS_TYPE,
  IS_CUSTOMER,
  IS_B2B,
  B2BCLASSTYPE_B_ID,
  B2BCLASSTYPE_N_ID,
  B2C_ID,
  B2BCLASSTYPE_C_ID,
  GUEST_ID,
  B2BCLASSTYPE_E_ID,
  B2BCLASSTYPE_S_ID,
  B2BCLASSTYPE_B_ID_NAME,
  B2BCLASSTYPE_N_ID_NAME,
  B2C_ID_NAME,
  B2BCLASSTYPE_C_ID_NAME,
  B2BCLASSTYPE_E_NAME,
  B2BCLASSTYPE_S_NAME,
  IS_CALL_CENTER,
  IS_EMPLOYEE,
} from "./constants";

export const IS_CUSTOMER_VAL =
  localStorage.getItem(IS_CUSTOMER) &&
  localStorage.getItem(IS_CUSTOMER) === "yes"
    ? true
    : false;

export const IS_B2B_VAL =
  localStorage.getItem(IS_B2B) && localStorage.getItem(IS_B2B) === "yes"
    ? true
    : false;
export const IS_CALLCENTER_VAL =
  localStorage.getItem(IS_CALL_CENTER) &&
  localStorage.getItem(IS_CALL_CENTER) === "yes"
    ? true
    : false;

export const IS_EMPLOYEE_VAL =
  localStorage.getItem(IS_EMPLOYEE) &&
  localStorage.getItem(IS_EMPLOYEE) === "yes"
    ? true
    : false;

export const CLASS_TYPE = localStorage.getItem(B2B_ClASS_TYPE)
  ? localStorage.getItem(B2B_ClASS_TYPE)
  : null;

export const IS_GUEST =
  !IS_CUSTOMER_VAL && !IS_B2B_VAL && !IS_CALLCENTER_VAL ? true : false;

export const CLASS_VALUE = IS_CUSTOMER_VAL
  ? B2C_ID_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_B_ID
  ? B2BCLASSTYPE_B_ID_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_N_ID
  ? B2BCLASSTYPE_N_ID_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_C_ID
  ? B2BCLASSTYPE_C_ID_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_E_ID
  ? B2BCLASSTYPE_E_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_S_ID
  ? B2BCLASSTYPE_S_NAME
  : "Guest";

export const CLASS_VALUE_ID = IS_CUSTOMER_VAL
  ? B2C_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_B_ID
  ? B2BCLASSTYPE_B_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_N_ID
  ? B2BCLASSTYPE_N_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_C_ID
  ? B2BCLASSTYPE_C_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_E_ID
  ? B2BCLASSTYPE_E_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_S_ID
  ? B2BCLASSTYPE_S_ID
  : GUEST_ID;
