import { AUTH_TOKEN } from "../config/constants";

export const expireDate = (num) => {
  num = num * 1000;
  var now = Date.now();
  num = now + num;
  var date = new Date(num);
  return date.toString();
};

export const isExpire = () =>
  new Date(localStorage.getItem("expires_in")) > Date.now() ? false : true;

export const isAuthenticated = () =>
  localStorage.getItem(AUTH_TOKEN) && !isExpire() ? true : false;

export const GetParamByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
