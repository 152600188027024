import React, { Suspense, lazy, useState, useEffect, useCallback } from "react";
import "./App.css";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getClientTag, checkInApp, checkInWeb } from "./utils/checkurl";
import { retry } from "./utils/retryloadable";
import "./assets/scss/Home.scss";
import "./assets/scss/HomeMobile.scss";
import "./assets/scss/Utilities.scss";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import { isAuthenticated } from "./lib/auth";
import { removeFromLocalStorage } from "./utils/logoututil";
import { LOGIN_PEOPLE_DETAIL } from "./config/constants";
import clientV3 from "./apolloClient";
import { GET_THEME_BY_CLIENT } from "./queries/promotionquery";
import { theme } from "./Theme";
import { GorgiasProvider } from "./GorgiasContext";
import useFlymyaSessionHook from "./hooks/FlymyaSessionHook";
import { useIdleTimer } from "react-idle-timer";
import { IS_CUSTOMER_VAL } from "./config/typecheck";
import FlymyaIconLoading from "./components/FlymyaIconLoading";

//get dynamic routes
import routes, { addConditionalRoutes } from "./routes.mjs";
addConditionalRoutes(localStorage.getItem("clientActive"));

const MainPage = lazy(() => retry(() => import("./pages/MainPage")));
const MobileAppDownload = lazy(() =>
  retry(() => import("./pages/MobileAppDownload")),
);
const FlightResult = lazy(() =>
  retry(() => import("./pages/flight/FlightResult")),
);
const FlightInterRoundtripResult = lazy(() =>
  retry(() =>
    import("./pages/flight/internationalRoundtrip/FlightInterRoundtripResult"),
  ),
);
const CustomerInfo = lazy(() =>
  retry(() => import("./pages/flight/CustomerInfo")),
);
const PaymentPage = lazy(() =>
  retry(() => import("./pages/flight/PaymentPage")),
);
const ConfirmationPage = lazy(() =>
  retry(() => import("./pages/confirmation/ConfirmationPage")),
);

const AddressBook = lazy(() =>
  retry(() => import("./pages/loginusers/AddressBook")),
);
const AddressBookDetail = lazy(() =>
  retry(() => import("./pages/loginusers/AddressBookDetail")),
);

const ForgetPassword = lazy(() =>
  retry(() => import("./pages/forgetpassword/ForgetPassword")),
);
const CreatePassword = lazy(() =>
  retry(() => import("./pages/CreatePassword")),
);

const HotelSearchResult = lazy(() =>
  retry(() => import("./pages/hotels/HotelSearchResult")),
);
const HotelCustomerPage = lazy(() =>
  retry(() => import("./pages/hotels/HotelCustomerPage")),
);
const HotelLongStayResult = lazy(() =>
  retry(() => import("./pages/hotellong/HotelLongStayResult")),
);
const HotelLongStayResultDetail = lazy(() =>
  retry(() => import("./pages/hotellong/HotelLongStayResultDetail")),
);

const HotelPaymentPage = lazy(() =>
  retry(() => import("./pages/hotels/HotelPaymentPage")),
);
const ShowResultMessage = lazy(() =>
  retry(() => import("./pages/flight/ShowResultMessage")),
);

const BusResult = lazy(() => retry(() => import("./pages/buses/BusResult")));
const BusSeatList = lazy(() =>
  retry(() => import("./pages/buses/BusSeatList")),
);
const BusCustomerInfo = lazy(() =>
  retry(() => import("./pages/buses/BusCustomerInfo")),
);
const BusTransactionFail = lazy(() =>
  retry(() => import("./pages/buses/BusTransactionFail")),
);

const PremiumPolicyPage = lazy(() =>
  retry(() => import("./pages/insurance/PremiumPolicyPage")),
);

const TourPackagesHome = lazy(() => retry(() => import("./pages/tourpackage")));

const PromotionsPage = lazy(() => retry(() => import("./pages/Promotions")));
const PromotionSelectDetail = lazy(() =>
  retry(() => import("./pages/PromotionSelectDetail")),
);
const Page404 = lazy(() => retry(() => import("./pages/Page404")));
const B2BBalancePage = lazy(() =>
  retry(() => import("./pages/loginusers/B2BBalancePage")),
);
const B2BTopup = lazy(() => retry(() => import("./pages/loginusers/B2BTopup")));
const B2BTransfer = lazy(() =>
  retry(() => import("./pages/loginusers/B2BTransfer")),
);
const BookingHistory = lazy(() =>
  retry(() => import("./pages/loginusers/BookingHistory")),
);
const Profile = lazy(() => retry(() => import("./pages/loginusers/Profile")));
const ContactUs = lazy(() => retry(() => import("./pages/ContactUs")));
const AboutUs = lazy(() => retry(() => import("./pages/AboutUs")));
const PrivacyPolicy = lazy(() => retry(() => import("./pages/PrivacyPolicy")));
const PrivacyPolicyBoca = lazy(() =>
  retry(() => import("./pages/PrivacyPolicyBoca")),
);

const InsiderDashBoardPage = lazy(() =>
  retry(() => import("./pages/loginusers/InsiderDashboard")),
);
const InternationalHotelBooking = lazy(() =>
  retry(() => import("./pages/flight/InternationalHotelBooking")),
);

const BookingHistoryDetail = lazy(() =>
  retry(() => import("./pages/loginusers/BookingHistoryDetail")),
);

const VerifyTicket = lazy(() => retry(() => import("./pages/verify_ticket")));

const VerifyEmail = lazy(() =>
  retry(() => import("./pages/login/VerifyEmail")),
);

const VerifyArrangerEmail = lazy(() =>
  retry(() => import("./pages/loginusers/CreatePasswordVerifyEmail")),
);
const FlightEticketDownload = lazy(() =>
  retry(() => import("./pages/eticket_download/EticketDownload")),
);

const EmailLinkOtp = lazy(() =>
  retry(() => import("./pages/login/EmailLinkAuth")),
);

const GorgiasChatIcon = lazy(() =>
  retry(() => import("./components/GorgiasChatIcon")),
);
const EmployeePage = lazy(() => retry(() => import("./pages/EmployeePage")));

const App = () => {
  const [open, setOpen] = useState(false);
  const [clientTag] = useState(getClientTag(window.location.search));
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const [themeColor, setThemeColor] = useState(theme);
  const [logo, setLogo] = useState(null);
  const [clientActive, setClientActive] = useState(null);

  const { createNewSession } = useFlymyaSessionHook();

  //const idleTimeout = 5 * 60 * 1000; // 5 mins
  const idleTimeout = 30 * 60 * 1000; // 30 mins
  //const idleTimeout = 5 * 1000; // 5 sec

  const handleIdle = () => {
    //console.log("idle", true);
    createNewSession();
  };

  useIdleTimer({
    timeout: idleTimeout,
    //promptTimeout: idleTimeout / 2,
    //onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });

  useEffect(() => {
    const path = window.location.pathname;
    const checkPath = path.split("/");
    const isInApp =
      checkPath.length > 2 &&
      (checkPath[2] === "in-app" || checkPath[2] === "in-web")
        ? true
        : false;
    const getLoginDetailFromLS = localStorage.getItem(LOGIN_PEOPLE_DETAIL);

    if (
      isAuthenticated() &&
      IS_CUSTOMER_VAL &&
      (getLoginDetailFromLS === "false" ||
        getLoginDetailFromLS === "" ||
        getLoginDetailFromLS === "undefined") &&
      !isInApp &&
      checkPath.length > 2
    ) {
      removeFromLocalStorage();
      setOpen(true);
    }
  }, []);

  const getTheme = useCallback(() => {
    clientV3()
      .query({
        query: GET_THEME_BY_CLIENT,
        fetchPolicy: "network-only",
        variables: {
          param: (isInApp || isInWeb) && clientTag ? clientTag : "default",
          orderBy: [{ column: "id", order: "DESC" }],
          first: 20,
          page: 1,
        },
      })
      .then((response) => {
        setLogo(
          response?.data?.client?.attachments &&
            response?.data?.client?.attachments?.length > 0
            ? response?.data?.client?.attachments[0]?.download_link
            : null,
        );
        localStorage.setItem("clientActive", response.data?.client?.active);
        setClientActive(response.data?.client?.active);
        response.data.client?.active &&
          setThemeColor(
            createTheme({
              components: {
                MuiGrid: {
                  styleOverrides: {
                    root: {
                      "& .highlight": {
                        background: response.data.client.color1
                          ? response.data.client.color1
                          : "#6e51a2",
                        color: "#fff",
                      },
                      "& .client-theme-color": {
                        color: response.data.client.color1
                          ? response.data.client.color1
                          : "rgb(0, 172, 246)",
                      },
                      "& .client-theme-background": {
                        background: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "rgb(0, 172, 246)",
                      },
                      "& .client-triangleup-borderBottom": {
                        borderBottom: response.data.client.color1
                          ? `15px solid ${response.data.client.color1}`
                          : "rgb(0, 172, 246)",
                      },
                      "& .detailBox:hover": {
                        border: response.data.client.color1
                          ? `1px solid${response.data.client.color1}`
                          : "1px solid #6e51a2",
                      },
                      "& .fullCircle,.arrivalPoint": {
                        backgroundColor: response.data.client.color1
                          ? response.data.client.color1
                          : "#6e51a2",
                        border: response.data.client.color1
                          ? `1px solid${response.data.client.color1}`
                          : "1px solid #6e51a2",
                      },
                      "& .circle,.departurePoint": {
                        border: response.data.client.color1
                          ? `1px solid${response.data.client.color1}`
                          : "1px solid #6e51a2",
                      },
                      "& .covidInfoContainer": {
                        border: response.data.client.color1
                          ? `1px solid${response.data.client.color1}`
                          : "1px solid #6e51a2",
                      },
                      // "&.filterInfoContainer": {
                      //   border: response.data.client.color2
                      //     ? `1px solid${response.data.client.color2} !important`
                      //     : "1px solid #ffd41c !important",
                      // },
                      "& .CalendarDay__selected,.selectedMultiDateBox": {
                        backgroundColor: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                      },
                      "& .DateInput_fangStroke": {
                        fill: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                        stroke: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                      },
                      "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
                        {
                          backgroundColor: response.data.client.color1
                            ? `${response.data.client.color1} !important`
                            : "#6e51a2 !important",
                        },
                      "& .airportSearchIcon,.nextDate svg,.previousDate svg": {
                        color: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                      },
                      "& .airportNames:hover": {
                        color: response.data.client.color1
                          ? `${response.data.client.color1} !important`
                          : "#6e51a2 !important",
                        cursor: "pointer",
                      },
                    },
                  },
                },
                MuiButton: {
                  styleOverrides: {
                    root: {
                      "&.Mui-disabled": {
                        background: response.data.client.color1
                          ? response.data.client.color1
                          : "#6e51a2",
                        color: "#fff",
                      },
                      "&.btnEditSearch": {
                        color: "#f7911e",
                        border: "1px solid #f7911e !important",
                      },
                      "&.btnEditSearch:hover": {
                        color: clientTag === "kbz" ? "#ffd41c" : "#231f20",
                        backgroundColor: response.data.client.color1
                          ? `${response.data.client.color2} !important`
                          : "#ffd41c !important",
                      },
                      "&.btnFilterSubmit": {
                        color: "#fff",
                        backgroundColor: response.data.client.color1
                          ? `${response.data.client.color2}`
                          : "#00aeef !important",
                        border: response.data.client.color2
                          ? `1px solid ${response.data.client.color2}`
                          : "1px solid #00aeef !important",
                      },
                      "&.btnFilter": {
                        color: "#231f20",
                        backgroundColor: "transparent",
                        border: "1px solid #f7911e !important",
                      },
                      "&.btnFiltered": {
                        color: "#231f20",
                        backgroundColor: "#f7911e !important",
                        border: "1px solid #f7911e !important",
                      },
                      "&.btnFilter:hover": {
                        color: clientTag === "kbz" ? "#ffd41c" : "#231f20",
                        backgroundColor: response.data.client.color1
                          ? `${response.data.client.color2} !important`
                          : "#ffd41c !important",
                      },
                      "&.btnClearAll:hover": {
                        color: "#fff",
                      },
                      "&.choosePackBtn:hover": {
                        backgroundColor: response.data.client.color1
                          ? response.data.client.color1
                          : "#6e51a2",
                        color: "#fff",
                      },
                    },
                  },
                },
              },
              palette: {
                primary: {
                  main: response.data.client.color1
                    ? response.data.client.color1
                    : "#6e51a2",
                  bg:
                    clientTag !== "citizenspay" &&
                    clientTag !== "mpitesan" &&
                    clientTag !== "ayapay" &&
                    clientTag !== "momoney" &&
                    response.data.client.color1
                      ? response.data.client.color1
                      : clientTag === "mpitesan" ||
                        clientTag === "ayapay" ||
                        clientTag === "citizenspay" ||
                        clientTag === "momoney"
                      ? "#fff"
                      : "#6e51a2",
                  contrastText: "#fff",
                },
                info: {
                  main: response.data.client.color2
                    ? response.data.client.color2
                    : "#ffd41c",
                  contrastText: clientTag === "CR" || "" ? "#231f20" : "#fff",
                },
                secondary: {
                  main: response.data.client.color3
                    ? response.data.client.color3
                    : "#e6eaed",
                  contrastText: clientTag === "CR" || "" ? "#231f20" : "#fff",
                },
              },
            }),
          );
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientTag]);

  useEffect(() => {
    getTheme();
  }, [getTheme]);

  return (
    <ThemeProvider theme={themeColor ? themeColor : theme}>
      <BrowserRouter>
        <Suspense fallback={<FlymyaIconLoading />}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={5000}
            onClose={() => setOpen(false)}
            TransitionComponent={Slide}
          >
            <Alert
              onClose={() => setOpen(false)}
              variant="filled"
              severity="warning"
            >
              The sign-up form must first be filled out. Even so, you may still
              buy as a guest.
            </Alert>
          </Snackbar>
          <GorgiasProvider>
            <Routes>
              {!clientActive && (
                <Route path="*" element={<Navigate to="/en" replace />} />
              )}
              <Route
                exact
                path={routes.HOME}
                element={<MainPage logo={logo} />}
              />

              {/** Payment and Confirmation URL */}
              <Route
                exact
                path="/:locale/payment-method/:bookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/payment-method/:bookingId/:returnBookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/payment-method/:bookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/payment-method/:bookingId/:returnBookingId"
                element={<PaymentPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/confirmation/:bookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/confirmation/:bookingId/:returnBookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/confirmation/:bookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/confirmation/:bookingId/:returnBookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/confirmation/:bookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/confirmation/:bookingId/:returnBookingId"
                element={<ConfirmationPage logo={logo} />}
              />
              {/** End Payment and Confirmation URL */}

              {/** APK Download URL */}
              <Route
                exact
                path={routes.MOBILE_APP_DOWNLOAD}
                element={<MobileAppDownload logo={logo} />}
              />
              <Route
                exact
                path={routes.MOBILE_APP_DOWNLOAD_INAPP}
                element={<MobileAppDownload logo={logo} />}
              />
              <Route
                exact
                path={routes.MOBILE_APP_DOWNLOAD_INWEB}
                element={<MobileAppDownload logo={logo} />}
              />
              {/** End APK Download URL */}

              {/** For the redirect url */}
              <Route
                exact
                path={`/:locale/redirect/:clientName`}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path={routes.FLIGHT_HOME}
                element={<MainPage logo={logo} />}
              />
              <Route exact path="/" element={<MainPage logo={logo} />} />
              <Route
                exact
                path="/:locale/test"
                element={<MainPage logo={logo} />}
              />

              {/** Verify Ticket */}
              <Route
                exact
                path={routes.VERIFY_TICKET}
                element={<VerifyTicket logo={logo} />}
              />
              <Route
                exact
                path={routes.VERIFY_TICKET_INAPP}
                element={<VerifyTicket logo={logo} />}
              />
              <Route
                exact
                path={routes.VERIFY_TICKET_INWEB}
                element={<VerifyTicket logo={logo} />}
              />

              {/** Verify Email */}
              <Route
                exact
                path={routes.VERIFY_EMAIL}
                element={<VerifyEmail />}
              />

              {/** Redirect Verify Email */}
              <Route
                exact
                path={routes.VERIFY_EMAIL_REDIRECT}
                element={<VerifyArrangerEmail />}
              />

              {/** Redirect Email Link */}
              <Route
                exact
                path={routes.EMAIL_LINK_REDIRECT}
                element={<EmailLinkOtp />}
              />

              {/*Flight url*/}
              <Route
                exact
                path="/:locale/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID/:returnUUID"
                element={<FlightResult />}
              />
              <Route
                exact
                path="/:locale/flight/:flightType/customer-details/:intelRoundSameRoute/:flightUUID"
                element={<CustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/flight/:flightType/customer-details/:intelRoundSameRoute/:flightUUID/:returnFlightUUID"
                element={<CustomerInfo logo={logo} />}
              />

              {/** International Roundtrip Url */}
              <Route
                exact
                path="/:locale/flight/:flightType/roundtrip/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
                element={<FlightInterRoundtripResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/roundtrip/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
                element={<FlightInterRoundtripResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/roundtrip/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
                element={<FlightInterRoundtripResult logo={logo} />}
              />

              {/* Redirect to update password url */}
              <Route
                exact
                path={routes.FORGOT_PASSPORT}
                element={<ForgetPassword />}
              />
              <Route
                exact
                path={routes.CREATE_PASSPORT}
                element={<CreatePassword />}
              />
              {/*Flight url*/}

              {/*Hotel url*/}
              <Route
                exact
                path={routes.HOTEL_HOME}
                element={<MainPage logo={logo} />}
              />

              <Route
                exact
                path="/:locale/hotel/search/:currency/:nationality/:city/:checkIn/:checkOut/:adultChildRoom"
                element={<HotelSearchResult logo={logo} />}
              />
              {/* <Route
                exact
                path="/:locale/hotel/choose/:currency/:nationality/:city/:stars/:checkIn/:checkOut/:adultChildRoom/:hotelId"
                element={<HotelDetailPage logo={logo} />}
              /> */}
              <Route
                exact
                path="/:locale/hotel/customer/:currency/:nationality/:hotelId/:stars/:checkIn/:checkOut/:adult/:child/:blockIds/:blockCounts"
                element={<HotelCustomerPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/hotel/payment"
                element={<HotelPaymentPage />}
              />

              {/*Hotel url*/}
              {/* Hotel Long Stay */}
              <Route
                exact
                path={routes.HOTEL_LONGSTAY}
                element={<HotelLongStayResult />}
              />
              <Route
                exact
                path="/:locale/hotel-long-stay/:hotelId/:showPrice"
                element={<HotelLongStayResultDetail />}
              />
              {/*Bus url*/}
              <Route
                exact
                path={routes.BUS_HOME}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/bus/result/:departureCity/:arrivalCity/:departureDate/:nationality/:b2bClassType/:passengers"
                element={<BusResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/bus/select-seat/:busDetailId"
                element={<BusSeatList logo={logo} />}
              />
              <Route
                exact
                path="/:locale/bus/contact-info/:busDetailId"
                element={<BusCustomerInfo logo={logo} />}
              />
              <Route
                exact
                path={routes.BUS_TRANSITION_FAIL}
                element={<BusTransactionFail />}
              />
              {/*Bus url*/}

              {/* car rental url*/}
              <Route
                exact
                path={routes.CAR_RENTAL_HOME}
                element={<MainPage logo={logo} />}
              />
              {/* car rental url*/}

              {/* balloon url*/}
              <Route
                exact
                path={routes.BALLOON_HOME}
                element={<MainPage logo={logo} />}
              />
              {/* balloon url*/}

              {/* travel services url*/}
              <Route
                exact
                path="/:locale/travel-services/:type"
                element={<MainPage logo={logo} />}
              />
              {/* travel services url*/}

              {/** Eticket Download */}
              <Route
                exact
                path="/:locale/flight/eticket-download/:reservationID"
                element={<FlightEticketDownload />}
              />
              <Route
                exact
                path="/:locale/bus/eticket-download/:reservationID"
                element={<FlightEticketDownload />}
              />
              <Route
                exact
                path="/:locale/hotel/eticket-download/:reservationID"
                element={<FlightEticketDownload />}
              />
              {/* insurance url*/}
              <Route
                exact
                path={routes.INSURANCE_HOME}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path={routes.INSURANCE_PREMIUM_POLICY}
                element={<PremiumPolicyPage logo={logo} />}
              />
              {/* insurance url*/}

              {/* tour package url*/}
              <Route
                exact
                path={routes.TOURPACKAGES_HOME}
                element={<TourPackagesHome logo={logo} />}
              />
              <Route
                exact
                path="/:locale/tour-packages/:id"
                // element={<TourPackagesDetail logo={logo} />}
                element={<PromotionSelectDetail logo={logo} />}
              />
              {/* tour package url */}

              <Route
                exact
                path={routes.PROMOTION_HOME_INAPP}
                element={<PromotionsPage logo={logo} />}
              />
              {/* <Route
                exact
                path="/:locale/in-app/promotion/:title"
                element={<PromotionDetailPage logo={logo} />}
              /> */}
              <Route
                exact
                path={routes.PROMOTION_HOME}
                element={<PromotionsPage logo={logo} />}
              />
              {/* <Route
                exact
                path="/:locale/promotion/:title"
                element={<PromotionDetailPage logo={logo} />}
              /> */}
              <Route
                exact
                path="/:locale/promotion/:id"
                element={<PromotionSelectDetail logo={logo} />}
              />
              <Route exact path={routes.CONTACT_US} element={<ContactUs />} />
              <Route
                exact
                path={routes.CONTACT_US_INAPP}
                element={<ContactUs logo={logo} />}
              />
              <Route
                exact
                path={routes.CONTACT_US_INWEB}
                element={<ContactUs logo={logo} />}
              />
              {/* <Route
                exact
                path="/:locale/device-id-track"
                element={<DeviceIdTracePage logo={logo} />}
              /> */}
              <Route
                exact
                path={routes.ABOUT_US}
                element={<AboutUs logo={logo} />}
              />
              <Route
                exact
                path={routes.ABOUT_US_INAPP}
                element={<AboutUs logo={logo} />}
              />
              <Route
                exact
                path={routes.ABOUT_US_INWEB}
                element={<AboutUs logo={logo} />}
              />
              <Route
                exact
                path={routes.PRIVACY_POLICY}
                element={<PrivacyPolicy logo={logo} />}
              />
              <Route
                exact
                path={routes.PRIVACY_POLICY_INAPP}
                element={<PrivacyPolicy logo={logo} />}
              />
              <Route
                exact
                path={routes.PRIVACY_POLICY_INWEB}
                element={<PrivacyPolicy logo={logo} />}
              />
              <Route
                exact
                path={routes.PRIVACY_POLICY_BOCA}
                element={<PrivacyPolicyBoca />}
              />
              <Route element={<Page404 />} />

              <Route
                exact
                path="/:locale/balance/:b2buserId"
                element={<B2BBalancePage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/balance/:b2buserId"
                element={<B2BBalancePage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/balance/:b2buserId"
                element={<B2BBalancePage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/address-book/:userId"
                element={<AddressBook logo={logo} />}
              />
              <Route
                path="/:locale/in-app/address-book/:userId"
                exact
                element={<AddressBook logo={logo} />}
              />
              <Route
                path="/:locale/in-web/address-book/:userId"
                exact
                element={<AddressBook logo={logo} />}
              />
              <Route
                exact
                path="/:locale/address-book-detail/:userId"
                element={<AddressBookDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/address-book-detail/:userId"
                element={<AddressBookDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/address-book-detail/:userId"
                element={<AddressBookDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/booking-history/:b2buserId"
                element={<BookingHistory logo={logo} />}
              />
              <Route
                exact
                path="/:locale/booking-history/:b2buserId/booking-detail/:resId"
                element={<BookingHistoryDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/booking-history/:b2buserId"
                element={<BookingHistory logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/booking-history/:b2buserId/booking-detail/:resId"
                element={<BookingHistoryDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/booking-history/:b2buserId/booking-detail/:resId"
                element={<BookingHistoryDetail logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/booking-history/:b2buserId"
                element={<BookingHistory logo={logo} />}
              />

              <Route
                path="/:locale/profile/:userId"
                exact
                element={<Profile logo={logo} />}
              />
              <Route
                path="/:locale/in-app/profile/:userId"
                exact
                element={<Profile logo={logo} />}
              />
              <Route
                path="/:locale/in-web/profile/:userId"
                exact
                element={<Profile logo={logo} />}
              />
              <Route
                exact
                path="/:locale/insider-dashboard/:b2cuserPeopleId"
                element={<InsiderDashBoardPage logo={logo} />}
              />
              <Route
                path="/:locale/in-web/insider-dashboard/:b2cuserPeopleId"
                exact
                element={<InsiderDashBoardPage logo={logo} />}
              />
              <Route
                path="/:locale/in-app/insider-dashboard/:b2cuserPeopleId"
                exact
                element={<InsiderDashBoardPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/b2b-topup/:b2buserId/:currency/:type"
                element={<B2BTopup logo={logo} />}
              />
              <Route
                exact
                path="/:locale/b2b-transfer/:b2buserId/:currency/:type"
                element={<B2BTransfer logo={logo} />}
              />

              <Route
                exact
                path={routes.TEAM}
                element={<EmployeePage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/team/:name"
                element={<EmployeePage logo={logo} />}
              />

              {/* in-app flight url */}
              <Route
                exact
                path={routes.HOME_INAPP}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path={routes.HOME_INAPP_LOCALE}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path={routes.FLIGHT_HOME_INAPP}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID/:returnUUID"
                element={<FlightResult />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/customer-details/:intelRoundSameRoute/:flightUUID"
                element={<CustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/:flightType/customer-details/:intelRoundSameRoute/:flightUUID/:returnFlightUUID"
                element={<CustomerInfo logo={logo} />}
              />
              {/* in-app flight url */}

              {/* in-app bus url */}
              <Route
                exact
                path={routes.BUS_HOME_INAPP}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/result/:departureCity/:arrivalCity/:departureDate/:nationality/:b2bClassType/:passengers"
                element={<BusResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/select-seat/:busDetailId"
                element={<BusSeatList logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/contact-info/:busDetailId"
                element={<BusCustomerInfo logo={logo} />}
              />
              <Route
                exact
                path={routes.BUS_TRANSITION_FAIL_INAPP}
                element={<BusTransactionFail />}
              />
              {/* in-app bus url */}

              {/* in-app hotel url */}
              <Route
                exact
                path={routes.HOTEL_HOME_INAPP}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/hotel/search/:currency/:nationality/:city/:checkIn/:checkOut/:adultChildRoom"
                element={<HotelSearchResult logo={logo} />}
              />
              {/* <Route
                exact
                path="/:locale/in-app/hotel/choose/:currency/:nationality/:city/:stars/:checkIn/:checkOut/:adultChildRoom/:hotelId"
                element={<HotelDetailPage logo={logo} />}
              /> */}
              <Route
                exact
                path="/:locale/in-app/hotel/customer/:currency/:nationality/:hotelId/:stars/:checkIn/:checkOut/:adult/:child/:blockIds/:blockCounts"
                element={<HotelCustomerPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/hotel/payment"
                element={<HotelPaymentPage logo={logo} />}
              />
              {/* in-app hotel url */}

              {/*in-app Hotel Long Stay */}
              <Route
                exact
                path={routes.HOTEL_LONGSTAY_INAPP}
                element={<HotelLongStayResult />}
              />
              <Route
                exact
                path="/:locale/in-app/hotel-long-stay/:hotelId/:showPrice"
                element={<HotelLongStayResultDetail />}
              />
              {/*in-app Hotel Long Stay */}

              {/* car rental url*/}
              <Route
                exact
                path={routes.CAR_RENTAL_HOME_INAPP}
                element={<MainPage logo={logo} />}
              />
              {/* car rental url*/}

              {/* in-app balloon url*/}
              <Route
                exact
                path={routes.BALLOON_HOME_INAPP}
                element={<MainPage logo={logo} />}
              />
              {/* in-app balloon url*/}

              {/* in-app travel services url*/}
              <Route
                exact
                path="/:locale/in-app/travel-services/:type"
                element={<MainPage logo={logo} />}
              />
              {/* in-app travel services url*/}

              {/* in-app insurance url*/}
              <Route
                exact
                path={routes.INSURANCE_HOME_INAPP}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path={routes.INSURANCE_PREMIUM_POLICY_INAPP}
                element={<PremiumPolicyPage logo={logo} />}
              />
              {/* in-app insurance url*/}

              {/* in-app tour packages url*/}
              <Route
                exact
                path={routes.TOURPACKAGES_HOME_INAPP}
                element={<TourPackagesHome logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/tour-packages/:id"
                // element={<TourPackagesDetail logo={logo} />}
                element={<PromotionSelectDetail logo={logo} />}
              />
              {/* in-app tour packages url*/}

              {/* in-app promotion */}
              <Route
                exact
                path={routes.PROMOTION_HOME_INAPP}
                component={PromotionsPage}
              />
              <Route
                exact
                path="/:locale/in-app/promotion/:id"
                element={<PromotionSelectDetail logo={logo} />}
              />
              {/* <Route
                exact
                path="/:locale/in-app/promotion/:title"
                component={PromotionDetailPage}
              /> */}
              {/* in-app promotion */}

              {/* in-app employee page */}
              <Route
                exact
                path={routes.TEAM_INAPP}
                element={<EmployeePage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-app/team/:name"
                element={<EmployeePage logo={logo} />}
              />
              {/* in-app employee page */}

              {/* kbzpay inweb url*/}
              {localStorage.getItem("clientActive") && (
                <Route
                  exact
                  path="/:locale/in-web"
                  element={<MainPage logo={logo} active={clientActive} />}
                />
              )}

              {/* in-web flight url */}
              <Route
                exact
                path={routes.HOME_INWEB}
                element={
                  <MainPage
                    logo={logo}
                    active={localStorage.getItem("clientActive")}
                  />
                }
              />
              <Route
                exact
                path={routes.FLIGHT_HOME_INWEB}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID"
                element={<FlightResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID/:returnUUID"
                element={<FlightResult />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/customer-details/:intelRoundSameRoute/:flightUUID"
                element={<CustomerInfo logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/:flightType/customer-details/:intelRoundSameRoute/:flightUUID/:returnFlightUUID"
                element={<CustomerInfo logo={logo} />}
              />

              {/* in-web bus url */}
              <Route
                exact
                path={routes.BUS_HOME_INWEB}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/result/:departureCity/:arrivalCity/:departureDate/:nationality/:b2bClassType/:passengers"
                element={<BusResult logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/select-seat/:busDetailId"
                element={<BusSeatList logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/contact-info/:busDetailId"
                element={<BusCustomerInfo logo={logo} />}
              />
              <Route
                exact
                path={routes.BUS_TRANSITION_FAIL_INWEB}
                element={<BusTransactionFail logo={logo} />}
              />
              {/* in-web bus url*/}

              {/* in-web carrental url*/}
              <Route
                exact
                path={routes.CAR_RENTAL_HOME_INWEB}
                element={<MainPage logo={logo} />}
              />
              {/* in-web carrental url*/}

              {/* in-web balloon url*/}
              <Route
                exact
                path={routes.BALLOON_HOME_INWEB}
                element={<MainPage logo={logo} />}
              />
              {/* in-web balloon url*/}

              {/* in-web travel services url*/}
              <Route
                exact
                path="/:locale/in-web/travel-services/:type"
                element={<MainPage logo={logo} />}
              />
              {/* in-web travel services url*/}

              {/* in-web insurance url*/}
              <Route
                exact
                path={routes.INSURANCE_HOME_INWEB}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path={routes.INSURANCE_PREMIUM_POLICY_INWEB}
                element={<PremiumPolicyPage logo={logo} />}
              />
              {/* in-web insurance url*/}

              {/* in-web tour packages url*/}
              <Route
                exact
                path={routes.TOURPACKAGES_HOME_INWEB}
                element={<TourPackagesHome logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/tour-packages/:id"
                // element={<TourPackagesDetail logo={logo} />}
                element={<PromotionSelectDetail logo={logo} />}
              />
              {/* in-web tour packages url*/}

              {/* in-web hotel url */}
              <Route
                exact
                path={routes.HOTEL_HOME_INWEB}
                element={<MainPage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/hotel/search/:currency/:nationality/:city/:checkIn/:checkOut/:adultChildRoom"
                element={<HotelSearchResult logo={logo} />}
              />
              {/* <Route
                exact
                path="/:locale/in-web/hotel/choose/:currency/:nationality/:city/:stars/:checkIn/:checkOut/:adultChildRoom/:hotelId"
                element={<HotelDetailPage logo={logo} />}
              /> */}
              <Route
                exact
                path="/:locale/in-web/hotel/customer/:currency/:nationality/:hotelId/:stars/:checkIn/:checkOut/:adult/:child/:blockIds/:blockCounts"
                element={<HotelCustomerPage logo={logo} />}
              />
              {/* in-web hotel url */}

              {/*in-web Hotel Long Stay */}
              <Route
                exact
                path={routes.HOTEL_LONGSTAY_INWEB}
                element={<HotelLongStayResult />}
              />
              <Route
                exact
                path="/:locale/in-web/hotel-long-stay/:hotelId/:showPrice"
                element={<HotelLongStayResultDetail />}
              />
              {/*in-web Hotel Long Stay */}

              {/*in-web Employee Page */}
              <Route
                exact
                path={routes.TEAM_INWEB}
                element={<EmployeePage logo={logo} />}
              />
              <Route
                exact
                path="/:locale/in-web/team/:name"
                element={<EmployeePage logo={logo} />}
              />
              {/*in-web Employee Page */}

              <Route
                exact
                path={routes.PROMOTION_HOME_INWEB}
                element={<PromotionsPage logo={logo} />}
              />
              {/* <Route
                exact
                path="/:locale/in-web/promotion/:title"
                element={<PromotionDetailPage logo={logo} />}
              /> */}
              <Route
                exact
                path="/:locale/in-web/promotion/:id"
                element={<PromotionSelectDetail logo={logo} />}
              />
              <Route
                exact
                path={routes.SHOW_RESULT_MESSAGE_INAPP}
                element={<ShowResultMessage logo={logo} />}
              />
              <Route
                exact
                path={routes.SHOW_RESULT_MESSAGE}
                element={<ShowResultMessage />}
              />
              <Route
                exact
                path={routes.REDIRECT_INTL_HOTEL}
                element={<InternationalHotelBooking />}
              />
              <Route
                exact
                path="/:locale/in-web/flight/eticket-download/:reservationID"
                element={<FlightEticketDownload />}
              />
              <Route
                exact
                path="/:locale/in-web/bus/eticket-download/:reservationID"
                element={<FlightEticketDownload />}
              />
              <Route
                exact
                path="/:locale/in-web/hotel/eticket-download/:reservationID"
                element={<FlightEticketDownload />}
              />
              <Route
                exact
                path="/:locale/in-app/flight/eticket-download/:reservationID"
                element={<FlightEticketDownload />}
              />
              <Route
                exact
                path="/:locale/in-app/bus/eticket-download/:reservationID"
                element={<FlightEticketDownload />}
              />
              <Route
                exact
                path="/:locale/in-app/hotel/eticket-download/:reservationID"
                element={<FlightEticketDownload />}
              />
              {/* Note: Please define static routes first in routes.mjs for sitemap and run "node generate-sitemap.js". */}
            </Routes>
            <GorgiasChatIcon />
          </GorgiasProvider>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
