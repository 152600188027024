export const getCookie = (val) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieParts = cookie.split("=");
    const cookieName = cookieParts[0];
    const cookieValue = cookieParts.slice(1).join("=");

    if (cookieName === val) {
      return cookieValue;
    }
  }

  return null;
};
