import { gql } from "apollo-boost";

const GET_PROMOTION = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    promotion(orderBy: $orderBy, first: $first, page: $page, filter: $filter) {
      data {
        id
        slug
        search_url
        web_url
        customer_classes
        clients {
          id
          name
        }
        names {
          id
          language_id
          translation_text
          language {
            id
            code
          }
        }
        slug
        descriptions {
          id
          language_id
          translation_text
          language {
            id
            code
          }
        }
        call_to_action
        show_homepage_popup
        show_traveller_info
        manual_campaign {
          id
          name
        }
        product_type {
          id
          name
          product_group {
            name
          }
        }
        promotion_checkout_type {
          id
          name
        }
        employee {
          id
          email
        }
        promo_preview_attachment {
          id
          attachments {
            id
            filename
            download_link
          }
        }
        attachments {
          id
          filename
          download_link
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_PROMOTIONBANNER = gql`
  mutation createPromotion($input: promotionInput) {
    createPromotion(input: $input) {
      id
      search_url
      manual_campaign {
        id
        name
      }
      promotion_checkout_type {
        id
        name
      }
      employee {
        id
        email
      }
      attachments {
        id
        filename
        download_link
      }
    }
  }
`;

const GET_CHECKOUT_TYPE = gql`
  query promotionCheckoutType {
    promotionCheckoutType {
      id
      name
      promotion {
        id
        search_url
        manual_campaign {
          id
          name
        }
        promotion_checkout_type {
          id
          name
        }
        employee {
          id
          email
        }
        attachments {
          id
          filename
          download_link
        }
      }
    }
  }
`;

const GET_PROMOTION_BY_CLIENT = gql`
  query ($input: clientPromotionsInput) {
    clientPromotions(input: $input) {
      id
      online_status
      slug
      names {
        id
        language_id
        translation_text
        language {
          id
          code
        }
      }
      descriptions {
        id
        language_id
        translation_text
        language {
          id
          code
        }
      }
      popup_names {
        id
        language_id
        translation_text
        language {
          id
          code
        }
      }
      popup_descriptions {
        id
        language_id
        translation_text
        language {
          id
          code
        }
      }
      customer_classes
      call_to_action
      web_url
      order
      product_type {
        id
        name
        product_group {
          name
        }
      }
      promo_preview_attachment {
        id
        attachments {
          id
          filename
          download_link
        }
      }
      attachments {
        id
        filename
        download_link
      }

      client_promotion {
        order
        homepage
        popup
        active
        submit_name
        submit_name_mm
      }
    }
  }
`;
const GET_THEME_BY_CLIENT = gql`
  query ($param: String!) {
    client(param: $param) {
      id
      attachments {
        id
        filename
        download_link
      }
      active
      color1
      color2
      color3
    }
  }
`;
// const GET_THEME_BY_CLIENT = gql`
//   query (
//     $filter: [filterInput]
//     $first: Int!
//     $page: Int
//     $orderBy: [OrderByClause!]
//   ) {
//     clients(filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
//       data {
//         id
//         name
//         order
//         param
//         client_type
//         order
//         active
//         color1
//         color2
//         color3
//         promos {
//           id
//           code
//           client_promo {
//             active
//             homepage
//             popup
//             order
//           }
//         }
//         promotions {
//           id
//           name
//           client_promotion {
//             active
//             homepage
//             popup
//             order
//           }
//         }
//         attachments {
//           id
//           filename
//           download_link
//         }
//       }
//       paginatorInfo {
//         count
//         currentPage
//         firstItem
//         lastItem
//         lastPage
//         perPage
//         total
//       }
//     }
//   }
// `;

export {
  GET_PROMOTION,
  CREATE_PROMOTIONBANNER,
  GET_CHECKOUT_TYPE,
  GET_PROMOTION_BY_CLIENT,
  GET_THEME_BY_CLIENT,
};
