import { IS_EMPLOYEE } from "../../config/constants";

export const GET_REDIRECT_ARRANGER_EDIT_URL = (arranger) =>
  process.env.REACT_APP_V3_STAGING.includes("v4")
    ? `https://dash.flymya.com/#/arrangers/edit/${arranger?.id}`
    : `https://coffee-dash.mmflights.com/#/arrangers/edit/${arranger?.id}`;

export const GET_REDIRECT_RESERVATION_EDIT_URL = (resId) =>
  process.env.REACT_APP_V3_STAGING.includes("v4")
    ? `https://dash.flymya.com/#/reservations/edit/${resId}`
    : `https://coffee-dash.mmflights.com/#/reservations/edit/${resId}`;

export const VALIDATE_EMAIL = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const SET_AS_ARRANGER = "set_as_arranger";

export const EMPLOYEE_B2B_SEARCH = "employee_b2b_search";

const isEmployee =
  localStorage.getItem(IS_EMPLOYEE) &&
  localStorage.getItem(IS_EMPLOYEE) === "yes"
    ? true
    : false;

export const setAsArranger =
  isEmployee &&
  localStorage.getItem(SET_AS_ARRANGER) &&
  localStorage.getItem(SET_AS_ARRANGER) !== "null" &&
  localStorage.getItem(SET_AS_ARRANGER) !== ""
    ? JSON.parse(localStorage.getItem(SET_AS_ARRANGER))
    : null;

export const ARRANGER_LIST_PAGE = "arranger_list_page";

export const CREATE_NEW_ARRANGER = "employee_create_new_arranger";
